/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Helmet from 'react-helmet'
import { Layout } from '@leshen/gatsby-theme-contentful'

import { StaticImage } from 'gatsby-plugin-image'

import {
  Billboard,
  VariableContent,
  PackageCard,
  LeshenPhoneCTA,
  LinkButton,
  SVGImage,
  SplitContent,
  Brandy,
  Accordion,
  Image,
  useMapi,
} from '@leshen/gatsby-theme-leshen'

import {
  Stack,
  Columns,
  Column,
  Typography,
  Dropdown,
  ThemeProvider,
} from '@leshen/ui'
import { graphql, Link as GatsbyLink, navigate } from 'gatsby'

import theme from '../../../../gatsby-theme-dish/theme'
import getFullStateName from '../utils/getFullStateName'
import SeoCtaBar from '../components/SeoCtaBar'

const StateTemplate = ({ data }) => {
  const { rotatedNumber } = useMapi()

  const {
    Meta_Description,
    FAQ_1,
    FAQ_2,
    FAQ_3,
    FAQ_4,
    State,
    Promo_Code,
    Page_Path,
    Meta_Title,
  } = data.pageInfo.edges[0].node

  const State_Name = getFullStateName(State)

  const brandyPackageList = [
    'dn-americastop120',
    'dn-americastop200',
    'dn-americastop250',
  ]

  const packageList = brandyPackageList
    .map((packageId) =>
      data.allContentfulPackage.edges.find(
        ({ node }) => node.identifier === packageId
      )
    )
    .filter((node) => node)

  const passedData = {
    ...data,
    contentfulPage: {
      header: data.allContentfulHeader.edges[0].node,
      footer: data.allContentfulFooter.edges[0].node,
      seo: {
        canonical: '',
        description: Meta_Description,
        robots: 'index,follow',
        title: Meta_Title,
        titleTemplate: '',
      },
      path: Page_Path,
      promoCode: Promo_Code,
      customBreadCrumbs: [
        {
          shortName: 'Home',
          path: '/',
        },
        {
          shortName: 'Availability',
          path: '/near-me/availability',
        },
      ],
    },
  }

  const getFAQ = (shortName) => {
    const faq = {
      QA1: {
        question: `Is there Dish Network service in ${State_Name}?`,
        answer: (
          <Typography variant="feature">
            Yes! You can find DISH Network TV in {State_Name}. See which DISH TV
            packages are{' '}
            <GatsbyLink to="/near-me/availability">available</GatsbyLink> in
            your area today!
          </Typography>
        ),
      },
      QA2: {
        question: `Does ${State_Name} offer Dish Network service? `,
        answer: (
          <Typography variant="feature">
            Yes! {State_Name} offers DISH Network service in your area. See
            which DISH Network TV packages are{' '}
            <GatsbyLink to="/near-me/availability">available</GatsbyLink> in
            your area today
          </Typography>
        ),
      },
      QA3: {
        question: `Is Dish Network available in ${State_Name}?`,
        answer: (
          <Typography variant="feature">
            Yes! DISH Network is available in {State_Name}. See which TV plans
            from DISH Network are{' '}
            <GatsbyLink to="/near-me/availability">available</GatsbyLink> in
            your area today!
          </Typography>
        ),
      },
      QA4: {
        question: `Can I get Dish Network TV in ${State_Name}?`,
        answer: (
          <Typography variant="feature">
            Yes! You can get DISH Network TV in {State_Name}. See which{' '}
            <GatsbyLink to="/near-me/availability">
              TV plans are available in your area
            </GatsbyLink>{' '}
            today!
          </Typography>
        ),
      },
      QA5: {
        question: `Is Dish Satellite TV available in ${State_Name}?`,
        answer: (
          <Typography variant="feature">
            Yes! You can find DISH Satellite TV in {State_Name}. See which{' '}
            <GatsbyLink to="/near-me/availability">
              satellite TV packages
            </GatsbyLink>{' '}
            are available in your area today!
          </Typography>
        ),
      },
      QP1: {
        question: `How much does Dish Satellite TV cost in ${State}?`,
        answer: (
          <Typography variant="feature">
            While prices don’t vary by city, they do vary by TV package. View{' '}
            <GatsbyLink to="/packages">DISH Satellite TV packages</GatsbyLink>{' '}
            in {State_Name}.
          </Typography>
        ),
      },
      QP2: {
        question: `How much do Dish Satellite TV packages in ${State} cost? `,
        answer: (
          <Typography variant="feature">
            DISH Satellite TV packages vary by plan. View{' '}
            <GatsbyLink to="/packages">
              DISH Satellite TV and internet packages and prices
            </GatsbyLink>{' '}
            in {State_Name}.
          </Typography>
        ),
      },
      QP3: {
        question: `How much does Dish Network cost in ${State}?`,
        answer: (
          <Typography variant="feature">
            Depending on what TV package you get, the cost of DISH Network in{' '}
            {State_Name} can vary. Compare{' '}
            <GatsbyLink to="/packages">
              DISH Network packages and prices
            </GatsbyLink>{' '}
            in {State_Name}.
          </Typography>
        ),
      },
      QP4: {
        question: `How much is Dish TV in ${State}?`,
        answer: (
          <Typography variant="feature">
            DISH TV prices vary by plan. Compare{' '}
            <GatsbyLink to="/packages">DISH TV packages and prices</GatsbyLink>{' '}
            in {State_Name} today!
          </Typography>
        ),
      },
      QP5: {
        question: `How much do Dish Network TV packages in ${State} cost?`,
        answer: (
          <Typography variant="feature">
            DISH Network TV prices vary by plan.{' '}
            <GatsbyLink to="/packages">
              Compare DISH Network TV packages and prices
            </GatsbyLink>{' '}
            in {State_Name}.
          </Typography>
        ),
      },
      QC1: {
        question: `What is the number for Dish Network in ${State_Name}?`,
        answer: (
          <Typography variant="feature">
            The phone number for DISH Network in {State_Name} is{' '}
            <a href={`tel:${rotatedNumber}`}>{rotatedNumber}</a>.
          </Typography>
        ),
      },
      QC2: {
        question: `What is the phone number for Dish TV in ${State_Name}?`,
        answer: (
          <Typography variant="feature">
            The phone number for DISH TV in {State_Name} is{' '}
            <a href={`tel:${rotatedNumber}`}>{rotatedNumber}</a>.
          </Typography>
        ),
      },
      QC3: {
        question: `How do I contact Dish TV in ${State_Name}?`,
        answer: (
          <Typography variant="feature">
            You can contact DISH TV in {State_Name} by calling{' '}
            <a href={`tel:${rotatedNumber}`}>{rotatedNumber}</a>.
          </Typography>
        ),
      },
      QC4: {
        question: `How do I contact Dish Network in ${State_Name}?`,
        answer: (
          <Typography variant="feature">
            You can contact DISH Network in {State_Name} by calling{' '}
            <a href={`tel:${rotatedNumber}`}>{rotatedNumber}</a>.
          </Typography>
        ),
      },
      QC5: {
        question: `What is the phone number for Dish Satellite in ${State_Name}?`,
        answer: (
          <Typography variant="feature">
            DISH Satellite’s phone number in {State_Name} is{' '}
            <a href={`tel:${rotatedNumber}`}>{rotatedNumber}</a>.
          </Typography>
        ),
      },
      QB1: {
        question: `Does Dish TV in ${State} offer bundles with internet?`,
        answer: (
          <Typography variant="feature">
            Yes! DISH TV in {State_Name} partners with internet providers to
            bring internet and entertainment to your home. Save with{' '}
            <GatsbyLink to="/bundles">DISH TV bundles</GatsbyLink> today!
          </Typography>
        ),
      },
      QB2: {
        question: `Can I get internet with Dish TV in ${State}?`,
        answer: (
          <Typography variant="feature">
            Yes! You can get internet with DISH TV in {State_Name} with a
            bundle. Save with{' '}
            <GatsbyLink to="/bundles">DISH Network TV bundles</GatsbyLink>{' '}
            today.
          </Typography>
        ),
      },
      QB3: {
        question: `Is it possible to get internet with Dish TV?`,
        answer: (
          <Typography variant="feature">
            Yes! It is absolutely possible to bundle internet with your DISH TV
            service. Save with{' '}
            <GatsbyLink to="/bundles">DISH TV and internet bundles</GatsbyLink>{' '}
            today!
          </Typography>
        ),
      },
      QB4: {
        question: `Does Dish Network offer bundles in ${State}?`,
        answer: (
          <Typography variant="feature">
            Yes! DISH Network offers bundles in {State_Name} so you can get both
            internet and entertainment in your home. Save with{' '}
            <GatsbyLink to="/bundles">DISH Network bundles</GatsbyLink> .
          </Typography>
        ),
      },
      QB5: {
        question: `Does Dish Network offer TV and internet bundles in ${State}?`,
        answer: (
          <Typography variant="feature">
            Yes! DISH Network offers TV and internet bundles in {State_Name}.
            Save with{' '}
            <GatsbyLink to="/bundles">DISH TV bundle pricing</GatsbyLink> today.
          </Typography>
        ),
      },
    }
    return faq[shortName]
  }

  const faqSchemaData = (shortName, piece) => {
    const schemaFAQ = {
      QA1: {
        question: `Is there Dish Network service in ${State_Name}?`,
        answer: `Yes! You can find DISH Network TV in ${State_Name}. See which DISH TV packages are <a href='/near-me/availability'>available</a> in your area today!`,
      },
      QA2: {
        question: `Does ${State_Name} offer Dish Network service? `,
        answer: `Yes! ${State_Name} offers DISH Network service in your area. See which DISH Network TV packages are <a href='/near-me/availability'>available</a> in your area today`,
      },
      QA3: {
        question: `Is Dish Network available in ${State_Name}?`,
        answer: `Yes! DISH Network is available in ${State_Name}. See which TV plans from DISH Network are <a href='/near-me/availability'>available</a> in your area today!`,
      },
      QA4: {
        question: `Can I get Dish Network TV in ${State_Name}?`,
        answer: `Yes! You can get DISH Network TV in ${State_Name}. See which <a href='/near-me/availability'>TV plans are available in your area</a> today!`,
      },
      QA5: {
        question: `Is Dish Satellite TV available in ${State_Name}?`,
        answer: `Yes! You can find DISH Satellite TV in ${State_Name}. See which <a href='/near-me/availability'>satellite TV packages</a> are available in your area today!`,
      },
      QP1: {
        question: `How much does Dish Satellite TV cost in ${State}?`,
        answer: `While prices don’t vary by city, they do vary by TV package. View <a href='/packages'>DISH Satellite TV packages</a> in  ${State_Name}.`,
      },
      QP2: {
        question: `How much do Dish Satellite TV packages in ${State} cost? `,
        answer: `DISH Satellite TV packages vary by plan. View{' '} <a href='/packages'>DISH Satellite TV and internet packages and prices</a> in  ${State_Name}.`,
      },
      QP3: {
        question: `How much does Dish Network cost in ${State}?`,
        answer: `Depending on what TV package you get, the cost of DISH Network in ${State_Name} can vary. Compare <a href='/packages'>DISH Network packages and prices</a> in ${State_Name}.`,
      },
      QP4: {
        question: `How much is Dish TV in ${State}?`,
        answer: `DISH TV prices vary by plan. Compare <a href='/packages'>DISH TV packages and prices</a> in ${State_Name} today!`,
      },
      QP5: {
        question: `How much do Dish Network TV packages in ${State} cost?`,
        answer: `DISH Network TV prices vary by plan. <a href='/packages'>Compare DISH Network TV packages and prices</a> in  ${State_Name}.`,
      },
      QC1: {
        question: `What is the number for Dish Network in ${State_Name}?`,
        answer: `The phone number for DISH Network in ${State_Name} is <a href='tel:${rotatedNumber}}'>${rotatedNumber}</a>.`,
      },
      QC2: {
        question: `What is the phone number for Dish TV in ${State_Name}?`,
        answer: `The phone number for DISH TV in ${State_Name} is <a href='tel:${rotatedNumber}'>${rotatedNumber}</a>.`,
      },
      QC3: {
        question: `How do I contact Dish TV in ${State_Name}?`,
        answer: `You can contact DISH TV in ${State_Name} by calling <a href='tel:${rotatedNumber}'>${rotatedNumber}</a>.`,
      },
      QC4: {
        question: `How do I contact Dish Network in ${State_Name}?`,
        answer: `You can contact DISH Network in ${State_Name} by calling <a href='tel:${rotatedNumber}>${rotatedNumber}</a>.`,
      },
      QC5: {
        question: `What is the phone number for Dish Satellite in ${State_Name}?`,
        answer: `DISH Satellite’s phone number in ${State_Name} is{' '} <a href='tel:${rotatedNumber}'>${rotatedNumber}</a>.`,
      },
      QB1: {
        question: `Does Dish TV in ${State} offer bundles with internet?`,
        answer: `Yes! DISH TV in ${State_Name} partners with internet providers to bring internet and entertainment to your home. Save with <a href='/bundles'>DISH TV bundles</a> today!`,
      },
      QB2: {
        question: `Can I get internet with Dish TV in ${State}?`,
        answer: ` Yes! You can get internet with DISH TV in ${State_Name} with a bundle. Save with <a href='/bundles'>DISH Network TV bundles</a> today.`,
      },
      QB3: {
        question: `Is it possible to get internet with Dish TV?`,
        answer: `Yes! It is absolutely possible to bundle internet with your DISH TV service. Save with <a href='/bundles'>DISH TV and internet bundles</a> today!`,
      },
      QB4: {
        question: `Does Dish Network offer bundles in ${State}?`,
        answer: `Yes! DISH Network offers bundles in ${State_Name} so you can get both internet and entertainment in your home. Save with <a href='/bundles'>DISH Network bundles</a>.`,
      },
      QB5: {
        question: `Does Dish Network offer TV and internet bundles in ${State}?`,
        answer: `Yes! DISH Network offers TV and internet bundles in ${State_Name}. Save with <a href='/bundles'>DISH TV bundle pricing</a> today.`,
      },
    }
    return schemaFAQ?.[shortName]?.[piece]
  }

  const generateSchema = () => {
    const schema = `
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "${faqSchemaData(FAQ_1, 'question')}",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "${faqSchemaData(FAQ_1, 'answer')}"
            }
          },
          {
            "@type": "Question",
            "name": "${faqSchemaData(FAQ_2, 'question')}",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "${faqSchemaData(FAQ_2, 'answer')}"
            }
          },
          {
            "@type": "Question",
            "name": "${faqSchemaData(FAQ_3, 'question')}",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "${faqSchemaData(FAQ_3, 'answer')}"
            }
          },
          {
            "@type": "Question",
            "name": "${faqSchemaData(FAQ_4, 'question')}",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "${faqSchemaData(FAQ_4, 'answer')}"
            }
          }
        ]
      }
      `
    return schema
  }

  const cityDropdownData = data.cities.edges.map((current) => ({
    label: current.node.City,
    value: current.node.Page_Path,
  }))

  return (
    <ThemeProvider theme={theme}>
      <Layout
        data={passedData}
        main={
          <>
            <Helmet>
              <script type="application/ld+json">{`${generateSchema()}`}</script>
            </Helmet>
            <Billboard
              customLink={GatsbyLink}
              mainContent={
                <>
                  <Typography variant="h1">DISH TV in {State_Name}</Typography>
                  <Typography variant="h4">
                    With DISH TV, free is our middle name—​​FREE HD for Life
                    <sup>®</sup>, FREE standard professional installation, and
                    FREE DISH Anywhere™ app.
                  </Typography>
                  <LeshenPhoneCTA variant="hero" color="primary">
                    Order Now!
                  </LeshenPhoneCTA>
                </>
              }
              image={
                <Image
                  data={data.regionImage?.cloudinary[0]?.gatsbyImageData}
                  isImageCritical
                />
              }
            />
            <SeoCtaBar />

            <VariableContent
              backgroundColor="Light"
              mainContent={
                <>
                  <Typography variant="h2">
                    Search DISH TV availability in {State_Name} cities
                  </Typography>
                  <Dropdown
                    options={cityDropdownData}
                    onChange={(e) => navigate(e.value)}
                    placeholderText="Select City"
                    aoa=""
                  />
                  {cityDropdownData?.map((current) => (
                    <StyledLink href={current.value} key={current.value}>
                      {current.label}
                    </StyledLink>
                  ))}
                </>
              }
            />
            <VariableContent
              mainContent={
                <>
                  <Typography variant="h2">
                    DISH Network Packages in {State_Name}
                  </Typography>
                  <Typography variant="body">
                    DISH in {State_Name} brings you free standard professional
                    installation in up to six rooms with all the entertainment
                    you want. Get over 190 channels at an affordable price, plus
                    over 60,000 on-demand titles, for the best DISH has to
                    offer.
                  </Typography>
                </>
              }
              backgroundColor="White"
              alignMainContent="center"
            >
              <Stack spacing="xxl">
                <Columns>
                  {packageList.map((currentPackage) => (
                    <PackageCard
                      label={currentPackage.node.label}
                      packageData={{ ...currentPackage.node.brandy }}
                      key={currentPackage.node.contentful_id}
                      cta={
                        <LeshenPhoneCTA color="primary">Call</LeshenPhoneCTA>
                      }
                    />
                  ))}
                </Columns>
              </Stack>
            </VariableContent>
            <SplitContent
              backgroundColor="Light"
              image={
                <StaticImage
                  src="../images/people/couple-on-couch.jpg"
                  alt="Couple on couch"
                  placeholder="blurred"
                />
              }
              mobileImage={
                <StaticImage
                  src="../images/people/couple-on-couch.jpg"
                  alt="Couple on couch"
                  placeholder="blurred"
                />
              }
              mainContent={
                <div
                  data-sal="slide-right"
                  data-sal-delay="100"
                  data-sal-duration="500"
                  data-sal-easing="ease"
                >
                  <Typography variant="h1">
                    Local Channels with Satellite TV in {State_Name}
                  </Typography>
                  <Typography variant="body">
                    You&apos;ll always see what&apos;s happening in your
                    community with DISH local channels in {State_Name}. DISH
                    offers local channels in every package, covering local news,
                    sports, and everything in between.
                  </Typography>
                </div>
              }
            />
            <SplitContent
              backgroundColor=""
              image={
                <StaticImage
                  src="../images/people/people-celebrating.jpg"
                  alt="Sports fans celebrating"
                  placeholder="blurred"
                />
              }
              mobileImage={
                <StaticImage
                  src="../images/people/people-celebrating.jpg"
                  alt="Sports fans celebrating"
                  placeholder="blurred"
                />
              }
              mainContent={
                <div
                  data-sal="slide-right"
                  data-sal-delay="100"
                  data-sal-duration="500"
                  data-sal-easing="ease"
                >
                  <Typography variant="h1">
                    Cheer on Your Favorite Teams in {State_Name}
                  </Typography>
                  <Typography variant="body">
                    From college hoops to the National Football League, DISH in{' '}
                    {State_Name} has all your favorite sports in one spot. Never
                    miss a slam dunk, grand slam, or touchdown again with the
                    Multi-Sport Pack, which features 35 major networks
                    broadcasting multiple sports, including baseball, football,
                    basketball, hockey, and soccer.
                  </Typography>
                  <LinkButton to="/tv/channels" color="primary">
                    Get DISH sports channels today!
                  </LinkButton>
                </div>
              }
            />

            <VariableContent
              mainContent={
                <>
                  <Typography variant="h2">
                    More Value with DISH Channels
                  </Typography>
                  <Typography variant="h5">
                    DISH Network plans include equipment, programming, and
                    lifestyle extras that save money and enrich your TV
                    experience.
                  </Typography>
                  <LinkButton to="/deals" color="primary">
                    See All Deals
                  </LinkButton>
                </>
              }
              backgroundColor="Light"
              alignMainContent="center"
            >
              <Stack spacing="xxl" alignMainContent="center">
                <Columns cardStyled leftAligned={false}>
                  <Column
                    image={
                      <SVGImage
                        svg={data.cardsSix.cloudinary[0].svg.code}
                        presentationWidth={
                          data.cardsSix.cloudinary[0].svg.presentationWidth
                        }
                      />
                    }
                  >
                    <Typography variant="h4">
                      2-year TV Price Guarantee
                    </Typography>
                    <Typography>
                      DISH is the only provider that guarantees your TV price.
                      Just a 2-year commitment.
                    </Typography>
                  </Column>
                  <Column
                    image={
                      <SVGImage
                        svg={data.cardsOne.cloudinary[0].svg.code}
                        presentationWidth={
                          data.cardsOne.cloudinary[0].svg.presentationWidth
                        }
                      />
                    }
                  >
                    <Typography variant="h4">
                      HD Channels for $0 Extra
                    </Typography>
                    <Typography>
                      Smart HD DVR receiver and FREE HD for Life<sup>®</sup>
                    </Typography>
                  </Column>
                  <Column
                    image={
                      <SVGImage
                        svg={data.cardsFour.cloudinary[0].svg.code}
                        presentationWidth={
                          data.cardsFour.cloudinary[0].svg.presentationWidth
                        }
                      />
                    }
                  >
                    <Typography variant="h4">FREE DISH Anywhere App</Typography>
                    <Typography>
                      Stream live TV channels on mobile<sup>**</sup>
                    </Typography>
                    <Brandy
                      text="**Watching live and recorded TV anywhere requires an internet-connected Hopper w/Sling or Hopper 3 and compatible mobile device."
                      variant="legal"
                    />
                  </Column>
                  <Column
                    image={
                      <SVGImage
                        svg={data.cardsThree.cloudinary[0].svg.code}
                        presentationWidth={
                          data.cardsThree.cloudinary[0].svg.presentationWidth
                        }
                      />
                    }
                  >
                    <Typography variant="h4">
                      FREE DISH Voice Remote<sup>‡</sup>
                    </Typography>
                    <Brandy
                      text="‡Requires internet-connected Hopper, Joey, or Wally device. Customer must press Voice Remote button to activate feature."
                      variant="legal"
                    />
                  </Column>
                  <Column
                    image={
                      <SVGImage
                        svg={data.cardsFive.cloudinary[0].svg.code}
                        presentationWidth={
                          data.cardsFive.cloudinary[0].svg.presentationWidth
                        }
                      />
                    }
                  >
                    <Typography variant="h4">
                      80,000 On-Demand Titles
                    </Typography>
                    <Typography>
                      With new releases and time-tested classics
                    </Typography>
                  </Column>
                  <Column
                    image={
                      <SVGImage
                        svg={data.cardsTwo.cloudinary[0].svg.code}
                        presentationWidth={
                          data.cardsTwo.cloudinary[0].svg.presentationWidth
                        }
                      />
                    }
                  >
                    <Typography variant="h4">
                      Signal Reliability Guarantee
                    </Typography>
                    <Typography>
                      A reliable signal or your money back.
                    </Typography>
                    <Brandy text="See terms and conditions" variant="legal" />
                  </Column>
                </Columns>
              </Stack>
            </VariableContent>

            <VariableContent
              mainContent={
                <Typography variant="h2">Frequently Asked Questions</Typography>
              }
              alignMainContent="center"
            >
              <Stack spacing="xxl" alignMainContent="center">
                <Accordion
                  items={[
                    {
                      title: getFAQ(FAQ_1).question,
                      content: getFAQ(FAQ_1).answer,
                    },
                    {
                      title: getFAQ(FAQ_2).question,
                      content: getFAQ(FAQ_2).answer,
                    },
                    {
                      title: getFAQ(FAQ_3).question,
                      content: getFAQ(FAQ_3).answer,
                    },
                    {
                      title: getFAQ(FAQ_4).question,
                      content: getFAQ(FAQ_4).answer,
                    },
                  ]}
                />
              </Stack>
            </VariableContent>
          </>
        }
      />
    </ThemeProvider>
  )
}

StateTemplate.propTypes = {
  data: PropTypes.shape({}).isRequired,
}

export default StateTemplate

const StyledLink = styled.a`
  display: none !important;
`

export const query = graphql`
  query StateTemplateQuery(
    $regionImage: String!
    $pagePath: String!
    $stateAbbreviation: String!
  ) {
    site {
      ...LeshenMetadata
    }
    pageInfo: allDatasetManagerUsDishAoa(
      filter: { Page_Path: { eq: $pagePath } }
    ) {
      edges {
        node {
          FAQ_1
          FAQ_2
          FAQ_3
          FAQ_4
          City
          State
          Promo_Code
          Page_Path
          Meta_Title
          Meta_Description
        }
      }
    }
    states: allDatasetManagerUsDishAoa(filter: { Area_Type: { eq: "state" } }) {
      edges {
        node {
          State
          Page_Path
        }
      }
    }
    cities: allDatasetManagerUsDishAoa(
      filter: { State: { eq: $stateAbbreviation }, Area_Type: { eq: "city" } }
      sort: { fields: City, order: ASC }
    ) {
      edges {
        node {
          City
          Page_Path
        }
      }
    }
    regionImage: contentfulMedia(identifier: { eq: $regionImage }) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    cardsOne: contentfulMedia(contentful_id: { eq: "10RXUU06bfeQNujQ33nw8d" }) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    cardsTwo: contentfulMedia(contentful_id: { eq: "7RrgXh0OsN6R7DxA7cA4g" }) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    cardsThree: contentfulMedia(
      contentful_id: { eq: "57CN7M4fDu8qavmWY8gZn0" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    cardsFour: contentfulMedia(
      contentful_id: { eq: "6a423dO9Dp8QYHMKzOtzzd" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    cardsFive: contentfulMedia(
      contentful_id: { eq: "3bOaM3VFRfHEToahynz1p1" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    cardsSix: contentfulMedia(contentful_id: { eq: "6ALpoXJshgbTSUuTa7FjAe" }) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    cardsDisclaimer: brandyDisclaimer(brandy_id: { eq: "disclaimer-dany" }) {
      brandy_id
      symbol
      text
    }
    allContentfulPackage {
      edges {
        node {
          __typename # used to determine component to render
          id
          contentful_id # required to make association
          identifier # user created contentful identifier
          brandy {
            name
            details
            bullets {
              text
              disclaimer {
                text
                symbol
              }
            }
            price {
              currency
              sale
              amount
              duration
              prefix
              suffix
              before
              disclaimer {
                text
                symbol
              }
            }
          }
          label
        }
      }
    }
    packageCardsDisclaimerOne: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-pricing" }
    ) {
      brandy_id
      symbol
      text
    }
    packageCardsDisclaimerTwo: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-googleasst" }
    ) {
      brandy_id
      symbol
      text
    }
    footerDisclaimer: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-authorizedretailer" }
    ) {
      brandy_id
      symbol
      text
    }
    allContentfulHeader(
      filter: {
        identifier: {}
        id: { eq: "13ffb248-bb27-5cc3-9fc6-3ef5a08d7cdf" }
      }
    ) {
      edges {
        node {
          id
          __typename
          identifier
          navigationPosition
          hideLocation
          navigationPosition
          headerBackgroundColor
          headerTextColor
          topBarBackgroundColor
          topBarTextColor
          navigationBackgroundColor
          navigationTextColor
          hoverBackgroundColor
          hoverTextColor
          logoLink
          borderColor
          borderHeight
          textBlockAlignment
          textBlock {
            raw
          }
          linkGroups {
            ...LeshenLinkGroup
            ...LeshenNavigationLink
          }
          topBarLinks {
            ...LeshenNavigationLink
          }
          callToActions {
            ... on ContentfulButton {
              ...LeshenButton
            }
            ... on ContentfulPhoneNumber {
              ...LeshenPhoneNumber
            }
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
        }
      }
    }
    allContentfulFooter(
      filter: { id: { eq: "b16a45d6-24fc-5205-9fdb-5c3afb186cb3" } }
    ) {
      edges {
        node {
          __typename
          id
          identifier
          linkGroups {
            ...LeshenLinkGroup
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
          disclaimers {
            ...LeshenDisclaimer
          }
          navigationBackgroundColor
          navigationTextColor
          disclaimerBackgroundColor
          disclaimerTextColor
          logoLink
          logoBackgroundColor
          logoTextColor
          logoTopBorder
          logoBottomBorder
        }
      }
    }
  }
`
